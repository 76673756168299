import './style.css';
import { Map, View } from "ol";
import 'ol/ol.css';
import {Style, Stroke, Fill, Icon, Text} from 'ol/style';
import { useGeographic } from 'ol/proj';
import {MVT} from "ol/format";
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import CircleStyle from "ol/style/Circle";
import {apply} from "ol-mapbox-style";

const layer = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    format: new MVT(),
    url:'https://42zyogadfgipn4yzwthv2whg6u0utlwl.lambda-url.us-east-2.on.aws/planet/{z}/{x}/{y}.mvt',
    zoom: 7,
    minZoom: 4,
    maxZoom: 12,
  }),
});

/*
const vectorLayer = new VectorTileLayer({
  source: new VectorTile({
    url: 'http://lumsden.dvrdns.org:8080/planet',
  }),
  style: new Style({
    stroke: new Stroke({
      color: "gray",
      width: 1,
    }),
    fill: new Fill({
      color: "rgba(20,20,20,0.9)",
    }),
  }),
});
*/
useGeographic();
const map = new Map({
  target: "map",
  view: new View({
    center: [172.606201,-43.556510],
    zoom: 7,
    minZoom: 4,
    maxZoom: 12,
  }),
});
apply(map, '/style.json', {}).then(r => console.log(r));

map.addLayer(layer);
